import { withApplicationInsights } from "../../components/telemetry";
import CreatorDashboardPanel from '../../components/creatorDashboardPanel';

const CreatorDashboard = () => {

    return (
       <CreatorDashboardPanel />
    );
};

export default withApplicationInsights(CreatorDashboard, "CreatorDashboard");
