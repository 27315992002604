import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { AppContext } from "../models/applicationState";
import { useTranslation } from "react-i18next";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridToolbarContainer } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import React from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ConfirmationPanel from "./confirmationPanel";
import styled from "@emotion/styled";
import { IconButton, TextField, Button, Typography, Box, CircularProgress } from "@mui/material";
import { randomId } from "@mui/x-data-grid-generator";
import { UserRole } from "../models";
import { Attachment, getAttachments, createBlobInContainer, createAttachment, deleteBlobFromContainer, deleteAttachment } from "../services/attachmentService";
import { Context } from "./context";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { styled as styledS } from '@mui/material/styles';

interface AttachmentsUploadProps {
    height: string;
    meetingId?: string;
    itemId?: string;
    onAttachmentsCountChanged?: (count: number, meetingId?: string, itemId?: string) => Promise<void> | void;
    allowModifications?: boolean;
    uploadPrefix?: string;
    contextDisplayName?: string;
}

export const AttachmentUpload: FC<AttachmentsUploadProps> = (props: AttachmentsUploadProps): ReactElement => {
    const appContext = useContext<AppContext>(Context);

    const [attachmentsList, setAttachmentsList] = useState<Attachment[]>([]);
    const [fileSelected, setFileSelected] = useState<File | null>();
    const [fileUploaded, setFileUploaded] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [attachmentToDelete, setAttachmentToDelete] = useState(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        props.onAttachmentsCountChanged(attachmentsList?.length, props.meetingId, props.itemId);
    }, [attachmentsList]);

    useEffect(() => {
        getAttachments(props.meetingId, props.itemId).then((list: any) => {
            setAttachmentsList(list);
        })
    }, [fileUploaded]);

    useEffect(() => {
        onFileUpload();
    }, [fileSelected]);

    const onFileChange = (event: any) => {
        setFileSelected(event.target.files[0]);
    };

    const onFileUpload = async () => {
        if (fileSelected && fileSelected?.name) {
            setUploading(true);

            // UPLOAD TO AZURE BLOB
            const blob = await createBlobInContainer(props.uploadPrefix, fileSelected, props.meetingId, props.itemId);
            setAttachmentsList([...attachmentsList, blob]);
            setUploading(false);
            await createAttachment(blob, props.meetingId, props.itemId);

            // reset state
            setFileSelected(null);
            setFileUploaded(fileSelected.name);
            setInputKey(Math.random().toString(36));
        }
    };

    const handleDeleteClick = (id: GridRowId, deleted: Attachment) => async () => {
        setAttachmentToDelete({ ...deleted, id: id });
    };

    const handleDeleteConfirmed = async (deleted: Attachment) => {
        setAttachmentsList(attachmentsList.filter((row) => row.id !== deleted.id));
        if (!deleted.external) {
            await deleteBlobFromContainer(props.meetingId, deleted.name, props.itemId);
        }
        await deleteAttachment(deleted, props.meetingId, props.itemId);
    };

    const translate = (col: GridColDef) => {
        return { ...col, headerName: t(col.headerName) };
    };

    const nameColumn: GridColDef<any> = { field: 'displayName', headerName: 'attachment_pane.displayName', editable: false, flex: 1 }

    // const onDownload = (displayName: string, url: string) => () => {
    //     const link = document.createElement("a");
    //     link.download = displayName;
    //     link.href = url;
    //     link.click();
    //   };

    const downloadColumn: GridColDef<any> = {
        field: 'download',
        type: 'actions',
        headerName: 'attachment_pane.download',
        width: 100,
        cellClassName: 'actions',
        renderCell: (params) => params.row.external ? (
            <IconButton href={params.row.url.startsWith("http") ? params.row.url : "https://" + params.row.url} target="_blank">
                <AttachFileOutlinedIcon />
            </IconButton >
        ) : (
            <IconButton href={params.row.url} target="_blank" download>
                <FileDownloadOutlinedIcon />
            </IconButton >
        )
    }

    const deleteColumn: GridColDef<any> = {
        field: 'actions',
        type: 'actions',
        headerName: 'attachment_pane.delete',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
            return [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id, row)}
                    color="inherit"
                    disabled={!props.allowModifications || row.id.includes('-') || (appContext.state.invoker.role === UserRole.Actor && row.creatorEmail !== appContext.state.invoker?.email)}
                />
            ];
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const hideConfirmationModal = () => {
        setAttachmentToDelete(false);
    };

    const submitDelete = () => {
        handleDeleteConfirmed(attachmentToDelete);
        setAttachmentToDelete(undefined);
    };

    const CustomToolbar: FC = (): ReactElement => {
        const [linkUrl, setLinkUrl] = useState("");
        const [linkName, setLinkName] = useState("");

        const onLinkAdded = async () => {
            const attachment = {
                id: randomId(),
                url: linkUrl,
                name: linkName || linkUrl,
                displayName: linkName || linkUrl,
                external: true
            };
            const created = await createAttachment(attachment, props.meetingId, props.itemId);
            setAttachmentsList([...attachmentsList, created]);
            setLinkName("");
            setLinkUrl("");
            // await createBlobInContainer(props.uploadPrefix, fileSelected, props.meetingId, props.itemId);
        };

        return (<GridToolbarContainer>
            <TextField
                id="link_name"
                variant="standard"
                value={linkName}
                disabled={!props.allowModifications}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLinkName(event.target.value || "");
                }}
                placeholder={t('attachment_pane.link_name')}
            ></TextField>
            <TextField
                id="link_url"
                variant="standard"
                value={linkUrl}
                disabled={!props.allowModifications}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLinkUrl(event.target.value || "");
                }}
                placeholder={t('attachment_pane.link_url')}
            ></TextField>
            <Button variant="contained" startIcon={<AttachFileOutlinedIcon />} onClick={onLinkAdded} disabled={!linkUrl || !props.allowModifications}>
                <Typography variant="button" display="block">
                    {t('button.link')}
                </Typography>
            </Button>
            <Box sx={{ flex: 1 }}></Box>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onClick={onFileUpload}
                disabled={uploading || !props.allowModifications}
            >
                {t('button.upload')}
                <VisuallyHiddenInput type="file" onChange={onFileChange} key={inputKey || ''} />
            </Button>
            <Box style={{ display: (uploading ? 'inline' : 'none') }}>
                <CircularProgress size={30} />
            </Box>
        </GridToolbarContainer>);
    }

    const StyledGridOverlay = styledS('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        marginTop: '30px',
        paddingBottom: '30px',
        '& .no-rows-primary': {
            fill: '#3D4751',
            ...theme.applyStyles('light', {
            fill: '#AEB8C2',
            }),
        },
        '& .no-rows-secondary': {
            fill: '#1D2126',
            ...theme.applyStyles('light', {
            fill: '#E8EAED',
            }),
        },
    }));

    const CustomNoRowsOverlay = () => {
        return (
            <StyledGridOverlay>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width={96}
                    viewBox="0 0 452 257"
                    aria-hidden
                    focusable="false"
                >
                    <path
                    className="no-rows-primary"
                    d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
                    />
                    <path
                    className="no-rows-primary"
                    d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
                    />
                    <path
                    className="no-rows-primary"
                    d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
                    />
                    <path
                    className="no-rows-secondary"
                    d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
                    />
                </svg>
                <Box sx={{ mt: 2 }}>{t('attachment_pane.no_rows')}</Box>
            </StyledGridOverlay>
        );
    }

    return (
        <Box sx={{ height: props.height }}>
            <DataGrid autoHeight={true} rows={attachmentsList}
                columns={[nameColumn, downloadColumn, deleteColumn].map(translate)}
                slots={{ toolbar: CustomToolbar,  noRowsOverlay: CustomNoRowsOverlay }}
                hideFooter={true}
            />

            {attachmentToDelete &&
                <ConfirmationPanel
                    showModal={!!attachmentToDelete}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    entry={attachmentToDelete}
                    type={"attachment"}
                    action="delete"
                />}
        </Box>
    );
}