import { GridActionsCellItem, GridCellModesModel, GridCellParams, GridColDef, GridRowId, GridRowModel, GridRowsProp, GridSortDirection, GridSortModel, GridToolbar, GridTreeNode, GridValidRowModel } from "@mui/x-data-grid";
import React, { FC, MutableRefObject, useState } from "react";
import { ReactElement } from "react";
import { ReactComponent as SaveIcon } from "../icons/save_item.svg";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { handleCellClickAsEditing, handleCellModesModelChange } from "../util/tableHandlers";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { StyledDataGrid } from "./styledGrid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { TooltipIcon } from "./tooltipIcon";
import ConfirmationPanel from "./confirmationPanel";

interface EditableGridProps<R extends GridValidRowModel = any> {
    apiRef?: MutableRefObject<GridApiCommunity>;
    toolbar?: React.JSXElementConstructor<any>;
    onDelete?: (item: R) => void;
    disableDeleteModal?: boolean;
    modelType?: string;
    toolbarProps?: any;
    processRowUpdate?: (newRow: R, oldRow: R) => Promise<R> | R;
    isCellEditable?: (params: GridCellParams<any, GridValidRowModel, GridValidRowModel, GridTreeNode>) => boolean;
    extraActions?: (id: GridRowId, row?: R) => JSX.Element[];
    saveAction?: boolean;
    actionsHeaderKey?: string;
    rows: R[];
    columns: readonly GridColDef<R>[];
    height: string;
    sortField?: string;
    sortDirection?: GridSortDirection;
    updateSortModel?: (newSortModel: GridSortModel) => void;
    onCellDoubleClick?: (params: GridCellParams<any, GridValidRowModel, GridValidRowModel, GridTreeNode>) => void;
}

const EditableGrid: FC<EditableGridProps> = (props: EditableGridProps): ReactElement => {
    const [changedRow, setChangedRow] = React.useState<GridRowId>(undefined);
    const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
    const { t } = useTranslation();
    const [items, setItems] = useState<GridValidRowModel[]>(props.rows);

    React.useEffect(() => {
        setItems(props.rows);
    }, [props.rows]);

    //delete confirmation modal
    const [deleteItem, setDeleteItem] = useState<GridRowModel<GridValidRowModel>>(null);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

    // Handle the displaying of the modal based on id
    const showDeleteModal = (id: GridRowId) => () => {
        const item = props.rows.find(r => r.id === id.toString());
        if (props.disableDeleteModal) {
            handleDeleteClick(item);
            return;
        }
        setDeleteItem(item);
        setDeleteConfirmationModal(true);
    };
    // Hide the modal
    const hideConfirmationModal = () => {
        setDeleteConfirmationModal(false);
    };

    const handleDeleteClick = (item: GridRowModel<GridValidRowModel>) => {
        props.onDelete(item);
        setItems(items.filter((row) => row.id !== item.id));
    };

    // Handle the actual deletion of the item
    const submitDelete = () => {
        handleDeleteClick(deleteItem);
        setDeleteConfirmationModal(false);
    };

    //default sorting
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: props.sortField,
            sort: props.sortDirection
        },
    ]);

    function updateSortModel(newSortModel: GridSortModel) {
        setSortModel(newSortModel);
        if (props.updateSortModel) {
            props.updateSortModel(newSortModel);
        }
    }

    const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel): Promise<GridValidRowModel> | GridValidRowModel => {
        setChangedRow(undefined);
        if (props.processRowUpdate) {
            return props.processRowUpdate(newRow, oldRow);
        }
    }

    const getActions = (id: GridRowId, row: GridValidRowModel) => {
        const actions = [];
        if (props.saveAction)
            actions.push(
                <GridActionsCellItem
                    icon={<SaveIcon/>}
                    label="Save"
                    sx={{
                        color: 'primary.main',
                    }}
                    disabled={!id || id !== changedRow}
                />,
            );

        if (props.extraActions) {
            props.extraActions(id, row).forEach((element) => {
                actions.push(element);
            });
        }
        if (props.onDelete) {
            actions.push(
                <GridActionsCellItem
                    icon={<TooltipIcon titleKey={'general.delete'} icon={<DeleteIcon />} />}
                    label="Delete"
                    onClick={showDeleteModal(id)}
                    sx={{
                        color: 'primary.main',
                    }}
                />
            );
        }
        return actions;
    }

    const actionsColumn: GridColDef<any> = {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 230,
        cellClassName: 'actions',
        getActions: ({ id, row }) => getActions(id, row)
    }

    const translate = (col: GridColDef) => {
        //dashboard progress headerName includes dynamic dates and should not be used as translation key
        if (col.field.startsWith("progress")) return col;
        return { ...col, headerName: t(col.headerName) };
    };

    return (
        <Box sx={{ backgroundColor: "background.default" }}>
            <div style={{ height: props.height, width: '100%' }}>
                <StyledDataGrid rows={items}
                    apiRef={props.apiRef}
                    pageSizeOptions={[1000]}
                    columns={(props.saveAction || props.onDelete || props.extraActions) ? [...props.columns, { ...actionsColumn, headerName: props.actionsHeaderKey ?? actionsColumn.headerName }].map(translate) : props.columns.map(translate)}
                    cellModesModel={cellModesModel}
                    onCellModesModelChange={React.useCallback(handleCellModesModelChange(setCellModesModel), [])}
                    onCellClick={React.useCallback(handleCellClickAsEditing(setCellModesModel, setChangedRow), [])}
                    processRowUpdate={processRowUpdate}
                    showColumnVerticalBorder={true}
                    getRowClassName={(params) => {
                        return 'grid-theme'
                    }}
                    isCellEditable={props.isCellEditable}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => updateSortModel(newSortModel)}
                    slots={{
                        toolbar: props.toolbar || GridToolbar,
                    }}
                    slotProps={
                        props.toolbarProps
                    }
                    localeText={{
                        toolbarColumns: t('toolbar.columns'),
                        toolbarFilters: t('toolbar.filters'),
                        toolbarDensity: t('toolbar.density'),
                        toolbarExport: t('toolbar.export')
                    }}
                    onCellDoubleClick={(params) => {
                        props.onCellDoubleClick(params);
                    }}
                />
            </div>
            {deleteConfirmationModal &&
                <ConfirmationPanel
                    showModal={deleteConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    entry={deleteItem}
                    type={props.modelType}
                    action="delete"
                />}
        </Box>
    );
}

export default EditableGrid;