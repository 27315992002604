import { GridColDef } from "@mui/x-data-grid"

export const UNCATEGORIZED = "uncategorized";
export const ALL_TAGS = "all";

export type TagMetadata = {
    id: string
    projectId: string
    tagId: string
    decisions: number
    infos: number
    tasks: number
}

export const tagNameColumn: GridColDef = { field: 'tagName', headerName: 'tags.name', editable: false, width: 200, flex: 0.5 };
export const projectNameColumn: GridColDef = { field: 'projectName', headerName: 'tags.project', editable: false, width: 200, flex: 0.5 };
export const decisionsColumn = { field: 'decisions', headerName: 'tags.decisions', editable: false, width: 200 };
export const infosColumn = { field: 'infos', headerName: 'tags.infos', editable: false, width: 200 };
export const tasksColumn = { field: 'tasks', headerName: 'tags.tasks', editable: false, width: 200 };