import {
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Meeting, MeetingStatus } from "../../models";
import * as listActions from "../../actions/listActions";
import { Context } from "../../components/context";
import { AppContext } from "../../models/applicationState";
import { ListActions } from "../../actions/listActions";
import { bindActionCreators } from "../../actions/actionCreators";
import { withApplicationInsights } from "../../components/telemetry";
import { creatorColumn, dateColumn, inviteesColumn, libraryIsPublishedColumn, nameColumn, numberOfViewersColumn } from "../../models/meetingRepresentation";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid";

import { AttachmentsPane } from "../../components/attachmentsPane";
import { EventActions } from "../../actions/eventActions";
import * as eventActions from '../../actions/eventActions';
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Tooltip, Typography, useTheme } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import EditableGrid from "../../components/editableGrid";
import { ReactComponent as PublishedIcon } from "../../icons/mark_email_read_fill.svg";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { TooltipIcon } from "../../components/tooltipIcon";
import PersonIcon from '@mui/icons-material/Person';
import CustomToolbar from "../../components/customToolbar";
import * as customListActions from '../../actions/listActions';
import { GroupAddOutlined } from "@mui/icons-material";

const MeetingsLibraryPage = () => {
    const MEETING = "meeting";
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const [showAttachmentsForMeeting, setShowAttachmentsForMeeting] = useState(undefined);
    const [showMeetingNavigation, setShowMeetingNavigation] = useState(undefined);
    const { instance } = useMsal();
    const [selectedProjectFilters, setSelectedProjectFilters] = useState(appContext.state.selectedProjectFilters);
    const [meetings, setMeetings] = useState(appContext.state.lists || []);
    const [gridItems, setGridItems] = useState(meetings);
    const [showArchived, setShowArchived] = useState(false);
    const theme = useTheme();

    const actions = useMemo(
        () => ({
            lists: bindActionCreators(
                listActions,
                appContext.dispatch
            ) as unknown as ListActions,
            events: bindActionCreators(eventActions, appContext.dispatch) as unknown as EventActions,
        }),
        [appContext.dispatch]
    );

    useEffect(() => {
        if (selectedProjectFilters !== appContext.state.selectedProjectFilters) {
            setSelectedProjectFilters(appContext.state.selectedProjectFilters);
        }
    }, [appContext.state.selectedProjectFilters, selectedProjectFilters, navigate]);

    function matchesProjectFilters(meeting: Meeting): boolean {
        if (selectedProjectFilters.tagIds?.length) {
            return (selectedProjectFilters?.tagIds.some(tagId => meeting.defaultTags.includes(tagId)));
        }
        return selectedProjectFilters?.projectIds.includes(meeting.project);
    }

    useEffect(() => {
        if (selectedProjectFilters?.projectIds?.length || selectedProjectFilters?.tagIds?.length) {
            setGridItems(sortMeetings(meetings.filter(m => matchesProjectFilters(m))));
        } else {
            setGridItems(sortMeetings(meetings));
        }
    }, [meetings, selectedProjectFilters, navigate]);

    const sortMeetings = (meetings: Meeting[]) => {
        return [...(meetings || [])]?.sort((a, b) => {
            if (a.createdDate < b.createdDate) return 1;
            if (a.createdDate > b.createdDate) return -1;
            return 0;
        });
    }

    const setArchivedMeetingsAsync = async () => {
        const meetings = await customListActions.getArchived();
        setMeetings(sortMeetings(meetings));
    }
    
    useEffect(() => {
        if (!showArchived) {
            setMeetings(sortMeetings(appContext.state.lists?.filter((row) => !row.archived)));
        } else {
            setArchivedMeetingsAsync();
        }
    }, [appContext.state.lists, showArchived, navigate]);

    const onArchived = (archived: boolean) => {
        setShowArchived(archived);
    }

    const onMeetingSelected = async (meeting: Meeting) => {
        actions.lists.select(meeting);
    }

    const onEdit = (meeting: Meeting) => {
        if (meeting) {
            onMeetingSelected(meeting);
            navigate(`/meetings/${meeting.id}`);
        }
    };

    const onReview = (meeting: Meeting) => {
        if (meeting) {
            onMeetingSelected(meeting);
            navigate(`/meetings/${meeting.id}/review`);
        }
    };

    const onNavigate = (meeting: Meeting) => {
        if (meeting) {
            onMeetingSelected(meeting);
            if (meeting.status === MeetingStatus.Published) {
                navigate(`/library/${meeting.id}`);
            } else {
                console.info(`show navigation msg for ${meeting.id}`);
                setShowMeetingNavigation(meeting.id);
            }
        }
    };

    const toggleArchive = (meeting: Meeting) => () => {
        if (meeting) {
            meeting.archived = meeting.archived ? false : true;
            setMeetings(meetings.filter((row) => (row.id !== meeting.id)));
            actions.lists.save(meeting);
        }
    };

    const onDelete = (meeting: Meeting) => {
        if (meeting) {
            actions.lists.remove(meeting.id);
        }
    };

    const onMeetingUpserted = async (_newMeeting: Meeting, _oldMeeting?: Meeting) => {
        return;
    }

    const gridActions = (id: GridRowId, row: Meeting) => {
        const handleNavigate = (_id: GridRowId) => () => {
            onNavigate(row);
        };

        const handleEdit = (_id: GridRowId) => () => {
            onEdit(row);
        };

        const handleReview = (_id: GridRowId) => () => {
            onReview(row);
        };

        return [
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.open'} icon={<ChevronRightIcon />} />}
                label="Open"
                onClick={handleNavigate(id)}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.edit'} icon={<EditOutlinedIcon />} />}
                label="Edit"
                onClick={handleEdit(id)}
                disabled={row.status !== MeetingStatus.Published || row.creator !== instance.getActiveAccount()?.username}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'button.review'} icon={<GroupAddOutlined />} />}
                label="Review"
                onClick={handleReview(id)}
                disabled={row.status !== MeetingStatus.Published || row.creator !== instance.getActiveAccount()?.username}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={row.archived ? 'general.unarchive' : 'general.archive'} icon={row.archived ? <UnarchiveIcon /> : <ArchiveOutlinedIcon />} />}
                label="Archive"
                onClick={toggleArchive(row)}
                sx={{
                    color: 'primary.main',
                }}
            />
        ];
    }

    const onCellDoubleClick = (params) => {
        onNavigate(params.row);
    }

    return (
        <Box
            sx={{
                '& .scroll-overflow': {
                    overflow: 'auto !important'
                }
            }}>
            <CssBaseline />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1, marginBottom: 1 }}>
                {/* <Typography variant="body1" sx={{ marginLeft: 1, fontStyle: "italic" }}>
                    {t('library.overview')}
                </Typography> */}
            </Box>
            {showMeetingNavigation && (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg w-96">
                            <p className="text-base leading-normal mb-4">{t('library.not_published_navigation')}</p>
                            <div className="flex justify-center space-x-4 pt-4">

                                <Button variant="outlined" onClick={() => setShowMeetingNavigation(undefined)}>
                                    <Typography variant="button" display="block">
                                        {t('button.cancel')}
                                    </Typography>
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    setShowMeetingNavigation(undefined);
                                    navigate(`/meetings/${showMeetingNavigation}`);
                                }}>
                                    <Typography variant="button" display="block">
                                        {t('button.continue')}
                                    </Typography>
                                </Button>

                            </div>
                        </div>
                    </div>
                </>
            )}
            {showAttachmentsForMeeting
                && (
                    <AttachmentsPane
                        allowModifications={false}
                        height='70vh'
                        uploadPrefix={`meetings/${showAttachmentsForMeeting}/`}
                        meetingId={showAttachmentsForMeeting}
                        onClose={() => setShowAttachmentsForMeeting(undefined)}
                        onAttachmentsCountChanged={(count, meetingId, _itemId) => setMeetings(meetings.map(m => m.id === meetingId ? { ...m, attachments: count } : m))}
                    />
                )}
            <EditableGrid
                processRowUpdate={onMeetingUpserted}
                rows={gridItems}
                actionsHeaderKey=''
                onDelete={onDelete}
                onCellDoubleClick={onCellDoubleClick}
                columns={[
                    { ...dateColumn, editable: false },
                    { ...nameColumn, editable: false, flex: 0.2 },
                    {
                        ...creatorColumn,
                        width: 240,
                        renderCell: (params) => (
                            <Tooltip title={params.row.creator}>
                                <Box sx={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                }}>
                                    {params.row.creator === instance.getActiveAccount()?.username && (
                                        <Checkbox
                                            disabled
                                            checkedIcon={<PersonIcon />}
                                            checked={true} />
                                    )}
                                    <span>{params.row.creator}</span>
                                </Box>
                            </Tooltip>
                        ),
                    },
                    {...inviteesColumn, flex: 0.4},
                    numberOfViewersColumn,
                    {
                        ...libraryIsPublishedColumn as GridColDef,
                        renderCell: (params) => (
                            <Box>
                                {params.row.status === MeetingStatus.Published
                                    ? (
                                        <TooltipIcon titleKey={'library.published.status'}
                                            icon={<PublishedIcon color={theme.palette.primary.main} />} />
                                    // ) : t('library.not_published')
                                    ) : <TooltipIcon titleKey={'library.not_published'} icon={<PublishedIcon color={theme.palette.grey[400]} />} />
                                }
                            </Box>
                        ),
                    }]
                }
                height='85vh'
                extraActions={gridActions}
                modelType={MEETING}
                toolbar={CustomToolbar}
                toolbarProps={
                    {
                        toolbar: {
                            showSettings: false,
                            hideItemStatusToggles: true,
                            onArchived: onArchived,
                        }
                    }
                }
            />
        </Box>
    );
};

export default withApplicationInsights(MeetingsLibraryPage, "MeetingsLibraryPage");
