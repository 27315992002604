import { TFunction } from "i18next"
import dateFormatter from "../util/dateFormatter"
import { MeetingItem, MeetingItemStatus, RiskIndicator } from "./meetingItem"
import { GridColDef } from "@mui/x-data-grid"
import { t } from "i18next"
export interface MeetingItemRepresentation {
    id: string
    content: string
    status: MeetingItemStatus
    risk: RiskIndicator
    task: boolean
    decision: boolean
    info: boolean
    index?: number
    identifier?: string
    meetingId?: string
    meetingName?: string
    meetingDate?: Date
    meetingAttachments?: number
    attachments?: number
    description?: string
    creator?: string
    isSnapshot?: boolean
    snapshotFor?: string
    actor?: string
    category?: string
    dueDate?: Date
    overdue: boolean
    completedDate?: Date
    project?: string
    tags?: string[]
    comments?: string
    data?: MeetingItem
    archived?: boolean
    prioritized?: boolean
}

export const hasRepresentationChanged = (item: MeetingItemRepresentation): boolean => {
    const itemToSave = getMeetingItemFromRepresentation(item);
    if (item.data && JSON.stringify(item.data) === JSON.stringify(itemToSave)) {
        console.info(`Item representation for ${itemToSave.identifier || itemToSave.index} has no changes`);
        return false;
    }
    console.info(`OLD ITEM ${JSON.stringify(item.data)}`);
    console.info(`NEW ITEM ${JSON.stringify(itemToSave)}`);
    return true;
};

export const isStatusUpdated = (item: MeetingItemRepresentation): boolean => {
    return item.data?.status !== item.status;
};

export const emptyItemRepresentation = {
    id: "1111",
    content: '',
    status: MeetingItemStatus.NotStarted,
    task: true,
    decision: false,
    info: false
}

export const statusKeys = Object.values(MeetingItemStatus);

// ["notstarted", "inprogress", "completed", "cancelled", "onhold"]

export const statusTranslatedLabelOptions = (t: TFunction) => statusKeys.map(
    key => {
        return {
            value: key,
            label: t(`item_status.${key}`)
        };
    });

export const convertToRepresentation = (data: MeetingItem[] | undefined): MeetingItemRepresentation[] => {
    const mapped = (data || []).map(meetingItem => {
        return {
            ...meetingItem,
            task: meetingItem.types?.includes('task'),
            decision: meetingItem.types?.includes('decision'),
            info: meetingItem.types?.includes('info'),
            data: meetingItem
        } as MeetingItemRepresentation;
    });
    return mapped;
}

export const convertToRepresentationSortedByCreateDateAscending = (data: MeetingItem[] | undefined): MeetingItemRepresentation[] => {
    return convertToRepresentation(data).sort((a, b) => {
        if (a.data.createdDate < b.data.createdDate) return -1;
        if (a.data.createdDate > b.data.createdDate) return 1;
        return 0;
    })
}

export const getMeetingItemFromRepresentation = (item: MeetingItemRepresentation): MeetingItem => {
    if (!item) {
        return null;
    }
    const types = [];
    if (item.task) {
        types.push('task');
    }
    if (item.decision) {
        types.push('decision');
    }
    if (item.info) {
        types.push('info');
    }
    const { data, info, task, decision, ...itemWithoutData } = item;
    return {
        ...itemWithoutData,
        dueDate: itemWithoutData.dueDate ? new Date(itemWithoutData.dueDate) : undefined,
        meetingId: data?.meetingId || 'will be updated by parent components',
        id: data?.id,
        types
    }
}
//columns for the top row - adding a new meeting item
export const addIcon: GridColDef = { field: 'addIcon', editable: false, width: 60, display: 'flex'};
export const identifierColumn: GridColDef = { field: 'identifier', headerName: 'item.identifier', editable: false, width: 60, hideable: false};
export const indexColumn: GridColDef = { field: 'index', headerName: 'item.identifier', width: 50, editable: false, hideable: false, display: 'flex'};
export const meetingNameColumn: GridColDef = { field: 'meetingName', headerName: 'item.meetingName', editable: false }
export const creatorColumn: GridColDef = { field: 'creator', headerName: 'item.creator', editable: false}
export const categoryColumn: GridColDef = { field: 'category', headerName: 'item.category', editable: false}
export const contentColumn: GridColDef = { field: 'content', headerName: 'item.content', editable: false, width: 270}
export const taskColumn = {
    field: 'taskType',
    headerName: 'item.taskType',
    width: 70,
    editable: false,
    align: 'center',
    headerAlign: 'center'
}
export const typesColumn = {
    field: 'types',
    headerName: 'item.types',
    minWidth: 140,
    editable: false,
    align: 'center',
    headerAlign: 'center'
}

export const overdueColumn = {
    field: 'overdue',
    headerName: 'item.overdue',
    minWidth: 50,
    editable: false,
    align: 'center',
    display: 'flex',
    headerAlign: 'center'
}

export const prioritizedColumn = {
    field: 'prioritized',
    headerName: 'item.prioritized',
    minWidth: 50,
    editable: false,
    align: 'center',
    display: 'flex',
    headerAlign: 'center'
}

export const decisionColumn = {
    field: 'decisionType',
    headerName: 'item.decisionType',
    width: 70,
    editable: false,
    align: 'center',
    headerAlign: 'center'
}

export const informationColumn = {
    field: 'informationType',
    headerName: 'item.informationType',
    width: 70,
    editable: false,
    align: 'center',
    headerAlign: 'center'
}

export const actorColumn: GridColDef = { field: 'actor', headerName: 'item.actor', editable: false, display: 'flex'}

export const dueDateColumn: GridColDef = {
    field: 'dueDate',
    headerName: 'item.dueDate',
    type: 'date' as const,
    width: 110,
    editable: true,
    valueSetter: (value, row) => {
        if (value) {
            const localDate = new Date(value);
            localDate.setHours(12, 0, 0, 0);
            const utcTime = localDate.getTime() - localDate.getTimezoneOffset() * 60000;
            row.dueDate = new Date(utcTime);
            return row;
        }
        return row;
    },
    valueFormatter: value =>
        value ?
         dateFormatter.format(new Date(value)) : t('item.notAvailable')
}

export const riskColumn = {
    field: 'risk',
    headerName: '',
    editable: false,
    width: 10,
}
export const getRiskColor = (risk: RiskIndicator) : string => {
    switch (true) {
        case risk === RiskIndicator.Roadblock:
            return "#DA1E28";
        case risk === RiskIndicator.AtRisk:
            return "#FF832B";
        case risk === RiskIndicator.OnTrack:
            return "#108B38";
        default:
            return "transparent";
    }
}


export const createdDateColumn = {
    field: 'createdDate',
    headerName: 'item.createdDate',
    type: 'date' as const,
    editable: false,
    width: 130,
    valueFormatter: value =>
        value ? dateFormatter.format(new Date(value)) : t('item.notAvailable')
}


export const statusColumn: GridColDef<any> = {
    field: 'status', headerName: 'item.status',
    width: 150,
    editable: true
}

export const tagsColumn: GridColDef = {
    field: 'tags',
    headerName: 'item.tags',
    width: 200,
    editable: true,
    valueSetter: (value, row) => {
        if (value) {
            row.tags = value;
            return row;
        }
        return row;
    },
    display: 'flex'
};