export enum MeetingItemStatus {
    NotStarted = "notstarted",
    InProgress = "inprogress",
    Completed = "completed",
    Cancelled = "cancelled",
    OnHold = "onhold"
}

export enum RiskIndicator {
    NoRisk = "norisk",
    OnTrack = "ontrack",
    AtRisk = "atrisk",
    Roadblock = "roadblock"
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
    return (Object.values(enm) as unknown as string[]).includes(value)
        ? value as unknown as T
        : undefined;
}


export interface MeetingItem {
    id?: string
    meetingId: string
    content: string
    status: MeetingItemStatus
    risk: RiskIndicator
    index?: number
    identifier?: string
    types?: string[]
    description?: string
    creator? : string
    isSnapshot?: boolean
    snapshotFor?: string
    actor?: string
    viewers? :string[]
    category? : string
    dueDate?: Date
    attachments?: number
    completedDate?: Date
    createdDate?: Date
    updatedDate?: Date
    project?: string
    tags?: string[],
    statusLastUpdatedBy?: string
    comments?: string
    archived?: boolean
    prioritized?: boolean
}

export enum MeetingItemType {
    Task = "task",
    Info = "info",
    Decision = "decision"
}
