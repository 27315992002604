import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { GridValidRowModel } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';

interface FreeSingleSelectProps<T extends GridValidRowModel = any> {
    onValueSelected: (value: T) => void
    options: T[]
    value?: T
    placeholder?: string
    disabled?: boolean
    formatValues?: (values: T[]) => string[]
    formatInputs?: (values: (object | string)[]) => T[]
    isOptionEqualToValue?: (option: T, value: T) => boolean;
    disableChipDeletion?: boolean
    disableFreeSolo?: boolean
    sx?: {
        [key: string]: string | number | any
    }
}

const CustomPopper = function (props) {
    return <Popper {...props}
        style={{ width: "fit-content" }}
        placement="bottom-start" />;
};

const finalTheme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                // Name of the slot
                inputRoot: {
                    // Some CSS for preventing row height errors
                    // paddingRight:'0px !important', 
                    padding: '6px 0px 6px 0px !important'
                },
            },
        },
    },
});

function formatOption(option: any, props: FreeSingleSelectProps<any>): string {
    if (option) {
        if (props.formatValues) {
            const formatted = props.formatValues([option]);
            if (formatted?.length) {
                return formatted[0];
            }
        } else {
            return option.toString();
        }
    }
    return '';
}

export const FreeSingleSelect: React.FC<FreeSingleSelectProps> = (props: FreeSingleSelectProps): React.ReactElement => {
    return (
        <ThemeProvider theme={finalTheme}>
            <Autocomplete
                sx={props.sx}
                disableClearable
                PopperComponent={CustomPopper}
                selectOnFocus
                fullWidth
                freeSolo={!props.disableFreeSolo}
                options={(props.options || [])}
                value={props.value || ""}
                renderOption={(renderProps, option, { selected }) => {
                    return <li {...renderProps}>
                        {formatOption(option, props)}
                    </li>
                }
                }
                getOptionLabel={(option) => {
                    return formatOption(option, props)
                }}
                renderInput={(inputParams) => {
                    return <TextField
                        {...inputParams}
                        variant="standard"
                        placeholder={props.value || props.placeholder || ""}
                        onBlur={(_event) => {
                            if (!props.disableFreeSolo) {
                                props.onValueSelected(inputParams.inputProps.value);
                            }
                        }}
                        InputProps={{
                            ...inputParams.InputProps,
                            disableUnderline: true
                        }}
                    />
                }}
                onChange=
                {
                    (event, value, _reason) => {
                        event?.stopPropagation();
                        props.onValueSelected(props.formatInputs ? props.formatInputs([value])[0] : value);
                    }
                }
                isOptionEqualToValue={props.isOptionEqualToValue || ((option, value) => option === value)}
                disabled={props.disabled}
            />
        </ThemeProvider>
    );
}

