import config from "../config";
import { QueryOptions } from "@testing-library/react";
import { TagMetadataService } from "../services/tagMetadataService";
import { TagMetadata } from "../models/tagMetadata";

const tagMetadataService = new TagMetadataService(config.api.baseUrl, '/tags/metadata');

export interface ListActions {
    list(options?: QueryOptions): Promise<TagMetadata[]>
}

export const list = async (options?: QueryOptions): Promise<TagMetadata[]> => {
    return await tagMetadataService.getList(options);
}