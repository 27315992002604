import {
    useContext,
    useEffect,
    useState,
} from "react";
import { Context } from "../../components/context";
import { AppContext } from "../../models/applicationState";
import { useNavigate, useParams } from "react-router-dom";
import { withApplicationInsights } from "../../components/telemetry";
import { MeetingHeader } from "../../components/meetingHeader";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import CreatorDashboardPanel from "../../components/creatorDashboardPanel";
import { useTranslation } from "react-i18next";
import { ALL_TAGS, UNCATEGORIZED } from "../../models/tagMetadata";

const TagItemsPage = () => {
    const navigate = useNavigate();
    const appContext = useContext<AppContext>(Context);
    const { t } = useTranslation();
    
    const [projects, setProjects] = useState(appContext.state.invoker?.projects || []);
    const [tagName, setTagName] = useState('');

    const { tagId } = useParams();
    const { projectId } = useParams();

    useEffect(() => {
        if (appContext.state.invoker?.projects?.length) {
            setProjects(appContext.state.invoker?.projects);
        }
    }, [navigate, appContext.state.invoker?.projects]);

    useEffect(() => {
        const relevantProject = projects?.find(p => p.id === projectId);
        const relevantTag = relevantProject?.tags.find(t => t.id === tagId)?.name;
        const tagName = tagId === UNCATEGORIZED ? t('tags.uncategorized') : (
            tagId === ALL_TAGS ? t('tags.all') : relevantTag || t('tags.not_shared')
        );
        setTagName(`${t('tags.project')}: ${relevantProject?.name || t('tags.uncategorized')}, ${t('tags.name')}: ${tagName}`);
    }, [navigate, projects]);

    return (
        <Box>
            <CssBaseline />
            <MeetingHeader meetingName={tagName}
                backRouteParent="/tags/all"
            />

            <CreatorDashboardPanel showItemsForProject = {projectId} showItemsForTag = {tagId} showMyTasks/>
        </Box>
    );
};

export default withApplicationInsights(TagItemsPage, "TagItemsPage");
