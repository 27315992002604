import config from "../config";
import { DashboardService } from "../services/dashboardService";
import { QueryOptions } from "@testing-library/react";
import { DashboardData, MeetingItem } from "../models";
import getAxiosFactory from "../services/customAxios";

const dashboardService = new DashboardService(config.api.baseUrl, '/dashboard/creator');

export const list = async (options?: QueryOptions): Promise<DashboardData[]> => {
    return await dashboardService.getList(options);
}

export const listAllWithProjectAndTag = async (projectId: string, tagId: string, options?: QueryOptions): Promise<DashboardData[]> => {
    const axiosFactory = getAxiosFactory(config.api.baseUrl, '/tags/' + projectId + '/' + tagId);
    const realClient = await axiosFactory();
    const response = await realClient.request<DashboardData[]>({
        method: 'GET',
        params: options
    });

    return response?.data;
}

export const listDiffs = async (options?: QueryOptions): Promise<MeetingItem[]> => {
    const axiosFactory = getAxiosFactory(config.api.baseUrl, '/dashboard/creator/diffs');
    const realClient = await axiosFactory();
    const response = await realClient.request<MeetingItem[]>({
        method: 'GET'
    });
    return response?.data || [];
}