import {
    useContext,
    useEffect,
    useState,
} from "react";
import * as tagMetadataActions from "../../actions/tagMetadataActions";
import { Context } from "../../components/context";
import { AppContext } from "../../models/applicationState";
import { withApplicationInsights } from "../../components/telemetry";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";

import EditableGrid from "../../components/editableGrid";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TooltipIcon } from "../../components/tooltipIcon";
import CustomToolbar from "../../components/customToolbar";
import { ALL_TAGS, TagMetadata, UNCATEGORIZED, decisionsColumn, projectNameColumn, tagNameColumn, tasksColumn } from "../../models/tagMetadata";
import { useTranslation } from "react-i18next";

const TagLibraryPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const [selectedProjectFilters, setSelectedProjectFilters] = useState(appContext.state.selectedProjectFilters);
    const [tagMetadata, setTagMetadata] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [projects, setProjects] = useState(appContext.state.invoker?.projects || []);

    useEffect(() => {
        if (appContext.state.invoker?.projects?.length) {
            setProjects(appContext.state.invoker?.projects);
        }
    }, [navigate, appContext.state.invoker?.projects]);

    useEffect(() => {
        if (selectedProjectFilters !== appContext.state.selectedProjectFilters) {
            setSelectedProjectFilters(appContext.state.selectedProjectFilters);
        }
    }, [appContext.state.selectedProjectFilters, selectedProjectFilters, navigate]);

    function convertTagMetadataToGridData(tagMetadata: TagMetadata) {
        const project = projects?.find((project) => project.id === tagMetadata.projectId);
        const tag = project?.tags.find((tag) => tag.id === tagMetadata.tagId);
        const tagName = tagMetadata.tagId === UNCATEGORIZED ? t('tags.uncategorized') : (
            tagMetadata.tagId === ALL_TAGS ? t('tags.all') : tag?.name || t('tags.not_shared')
        );
        return {
            id: tagMetadata.id,
            tagName: tagName,
            tagId: tagMetadata.tagId,
            projectName: tagMetadata.projectId === UNCATEGORIZED ? t('tags.uncategorized') : project?.name || t('tags.not_shared'),
            projectId: tagMetadata.projectId,
            decisions: tagMetadata.decisions,
            infos: tagMetadata.infos,
            tasks: tagMetadata.tasks,
        };
    }
    async function fetchData() {
        const response = await tagMetadataActions.list({ archived: showArchived });
        const mapped = response.map(convertTagMetadataToGridData);
        // Sort by project name; equal project names are sorted by tag name
        setTagMetadata(mapped.sort((a, b) => {
            if (a.projectName === b.projectName) {
                return a.tagName.localeCompare(b.tagName);
            }
            return a.projectName.localeCompare(b.projectName);
        }
        ));
    }

    useEffect(() => {
        if (selectedProjectFilters !== appContext.state.selectedProjectFilters) {
            setSelectedProjectFilters(appContext.state.selectedProjectFilters);
        }
        fetchData();
    }, [appContext.state.selectedProjectFilters, selectedProjectFilters, navigate, showArchived]);

    const onArchived = (archived: boolean) => {
        setShowArchived(archived);
    }

    const onNavigate = (tag: TagMetadata) => {
        if (tag) {
            navigate(`/tags/${tag.projectId}/${tag.tagId}`);
        }
    };

    const onMeetingUpserted = async (_newEntry: TagMetadata, _oldEntry?: TagMetadata) => {
        return;
    }

    const gridActions = (id: GridRowId, row: TagMetadata) => {
        const handleNavigate = (_id: GridRowId) => () => {
            onNavigate(row);
        };

        return [
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.open'} icon={<ChevronRightIcon />} />}
                label="Open"
                onClick={handleNavigate(id)}
                sx={{
                    color: 'primary.main',
                }}
            />
        ];
    }

    return (
        <Box
            sx={{
                '& .scroll-overflow': {
                    overflow: 'auto !important'
                }
            }}>
            <CssBaseline />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginTop: 1, marginBottom: 1 }}>

            </Box>

            <EditableGrid
                processRowUpdate={onMeetingUpserted}
                rows={tagMetadata}
                actionsHeaderKey=''
                columns={[
                    projectNameColumn,
                    tagNameColumn,
                    decisionsColumn,
                    tasksColumn,
                ]
                }
                height='85vh'
                extraActions={gridActions}
                modelType={''}
                toolbar={CustomToolbar}
                toolbarProps={
                    {
                        toolbar: {
                            showSettings: false,
                            hideItemStatusToggles: true,
                            onArchived: onArchived,
                        }
                    }
                }
            />
        </Box>
    );
};

export default withApplicationInsights(TagLibraryPage, "TagLibraryPage");
