import {
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Context } from "../../components/context";
import { AppContext } from "../../models/applicationState";
import { withApplicationInsights } from "../../components/telemetry";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Container } from "@mui/material";
import { MeetingHeaderWithTabs } from "../../components/meetingHeaderWithTabs";
import * as itemActions from "../../actions/itemActions";
import * as listActions from "../../actions/listActions";
import { useTranslation } from "react-i18next";
import { AttachmentsPane } from "../../components/attachmentsPane";
import { bindActionCreators } from "../../actions/actionCreators";
import { ListActions } from "../../actions/listActions";
import { ItemActions } from "../../actions/itemActions";
import { MeetingItem } from "../../models";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { MeetingItemRepresentation, convertToRepresentationSortedByCreateDateAscending } from "../../models/meetingItemRepresentation";
import { LibraryItemsPageTab } from "../../components/meetingLibraryItemsTab";


const LibraryItemsPage = () => {
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const location = useLocation();
    const [value, setValue] = useState(location.pathname.includes('published') ? 0 : 1);
    const [showAttachmentsPane, setShowAttachmentsPane] = useState(false);
    const { meetingId } = useParams();
    const navigate = useNavigate();
    const [liveItems, setLiveItems] = useState<MeetingItemRepresentation[]>([]);
    const [frozenItems, setFrozenItems] = useState<MeetingItemRepresentation[]>([]);

    const actions = useMemo(
        () => ({
            lists: bindActionCreators(
                listActions,
                appContext.dispatch
            ) as unknown as ListActions,
            items: bindActionCreators(
                itemActions,
                appContext.dispatch
            ) as unknown as ItemActions,
        }),
        [appContext.dispatch]
    );

    useEffect(() => {
        if (meetingId && appContext.state.selectedList?.id !== meetingId) {
            actions.lists.load(meetingId);
        }
    }, [actions.lists, appContext.state.selectedList, meetingId, navigate])

    const filterOnlySnapshotItems = (items: MeetingItem[]) => {
        return items?.filter(i => !!i.isSnapshot) || [];
    }

    const filterOnlyCurrentItems = (items: MeetingItem[]) => {
        return items?.filter(i => !i.isSnapshot) || [];
    }

    // Load items for selected list
    useEffect(() => {
        if (appContext.state.selectedList?.id && !appContext.state.selectedList.items) {
            const loadListItems = async (listId: string) => {
                await actions.items.list(listId);
            }
            loadListItems(appContext.state.selectedList.id)
        }
        if (appContext.state.selectedList?.items) {
            const frozen = filterOnlySnapshotItems(appContext.state.selectedList.items);
            const live = filterOnlyCurrentItems(appContext.state.selectedList.items);
            setFrozenItems(convertToRepresentationSortedByCreateDateAscending(frozen));
            setLiveItems(convertToRepresentationSortedByCreateDateAscending(live));
        }
    }, [actions.items, appContext.state.selectedList?.id, appContext.state.selectedList?.items])

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Box>
            <CssBaseline />
            <MeetingHeaderWithTabs meeting={appContext.state.selectedList}
                setValue={setValue}
                initialValue={value}
                tabs={[
                    {
                        label: t('library.published.title'),
                        icon: "archiveIcon",
                        message: t('library.published.note')
                    },
                    {
                        label: t('library.current.title'),
                        icon: "dynamicFeedIcon",
                        message: t('library.current.note')
                    },
                ]}
                secondaryBtnText={t('button.attachments', { count: appContext.state.selectedList?.attachments || 0 })}
                shareBtnText={t('button.share')}
                onSecondaryBtnClick={() => setShowAttachmentsPane(true)}
                editMeetingBtnText={t('general.edit')}
                onEditMeetingBtnClick={() => navigate(`/meetings/${appContext.state.selectedList?.id}`)}
                reviewMeetingBtnText={t('button.review')}
                onReviewMeetingBtnClick={() => navigate(`/meetings/${appContext.state.selectedList?.id}/review`)}
                backRouteParent="/library"
            />
            {showAttachmentsPane && (
                <AttachmentsPane
                    allowModifications={true}
                    height='70vh'
                    uploadPrefix={`meetings/${appContext.state.selectedList?.id}/`}
                    meetingId={appContext.state.selectedList?.id}
                    onClose={() => setShowAttachmentsPane(false)}
                    onAttachmentsCountChanged={(count, _meetingId, _itemId) => { appContext.state.selectedList.attachments = count }}
                />
            )}
            <Container maxWidth={false} disableGutters>
                                <TabPanel value={value} index={0}>
                    <div>
                        <LibraryItemsPageTab
                            readOnly={true}
                            meeting={appContext.state.selectedList}
                            rows={frozenItems} />
                    </div>
                </TabPanel>
                                <TabPanel value={value} index={1}>
                    <div>
                        <LibraryItemsPageTab
                            meeting={appContext.state.selectedList}
                            rows={liveItems} />
                    </div>
                </TabPanel>
                            </Container>
        </Box>
    );
};

export default withApplicationInsights(LibraryItemsPage, "ItemsPage");
