import { GridActionsCellItem, GridColDef, GridRowId, GridRowModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { withApplicationInsights } from "../../components/telemetry";
import { MeetingItem, MeetingItemStatus } from "../../models";
import { useContext, useEffect, useMemo, useState } from 'react';
import * as dashboardActions from '../../actions/actorDashboardActions';
import { bindActionCreators } from '../../actions/actionCreators';
import { AppContext } from '../../models/applicationState';
import { Context } from '../../components/context';
import { ItemActions } from '../../actions/itemActions';
import * as itemActions from '../../actions/itemActions';
import { Badge, Box, CssBaseline, Tooltip, useTheme } from '@mui/material';
import EditableGrid from '../../components/editableGrid';
import { MeetingItemRepresentation, categoryColumn, contentColumn, convertToRepresentation, createdDateColumn, creatorColumn, dueDateColumn, getMeetingItemFromRepresentation, getRiskColor, hasRepresentationChanged, isStatusUpdated, meetingNameColumn, overdueColumn, prioritizedColumn, riskColumn, statusColumn, statusTranslatedLabelOptions, typesColumn } from '../../models/meetingItemRepresentation';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MeetingAttachment } from "../../icons/meeting_attachment.svg";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AttachmentsPane } from '../../components/attachmentsPane';
import ItemStatus from '../../components/itemStatus';
import { useTranslation } from 'react-i18next';
import { TooltipIconWithBadge } from '../../components/tooltipIconWithBadge';
import CustomToolbar from '../../components/customToolbar';
import { ListActions } from '../../actions/listActions';
import * as listActions from "../../actions/listActions";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ItemEditingPanel, addStatusUpdateSystemCommentToItem, hasUserComments } from '../../components/itemEditingPanel';
import { TooltipIcon } from '../../components/tooltipIcon';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { ReactComponent as AlarmIcon } from "../../icons/alarm_fill.svg";
import { useMsal } from '@azure/msal-react';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const ActorDashboard = () => {
    const ACTOR_SORT_FIELD = "actor.sortField";
    const ACTOR_SORT_DIRECTION = "actor.sortDirection";
    const HIGHLIGHTED_ITEMS_KEY = "actorHighlightedItems"
    const navigate = useNavigate();
    const appContext = useContext<AppContext>(Context)
    const [items, setItems] = useState<MeetingItemRepresentation[]>([]);
    const [gridItems, setGridItems] = useState<MeetingItemRepresentation[]>([]);
    const [showCompleted, setShowCompleted] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [showOnlyPrioritized, setShowOnlyPrioritized] = useState(false);
    const [highlightedItemIds, setHghlightedItemIds] = useState<string[]>(JSON.parse(window.sessionStorage.getItem(HIGHLIGHTED_ITEMS_KEY)) || []);
    const [showAttachmentsForMeeting, setShowAttachmentsForMeeting] = useState(undefined);
    const [showAttachmentsForItem, setShowAttachmentsForItem] = useState(undefined);
    const { t } = useTranslation();
    const [selectedProjectFilters, setSelectedProjectFilters] = useState(appContext.state.selectedProjectFilters);
    const [showEditingPanelForItem, setShowEditingPanelForItem] = useState(undefined);
    const theme = useTheme();
    const { instance } = useMsal();

    const actions = useMemo(() => ({
        items: bindActionCreators(itemActions, appContext.dispatch) as unknown as ItemActions,
        lists: bindActionCreators(listActions, appContext.dispatch) as unknown as ListActions,
    }), [appContext.dispatch]);

    useEffect(() => {
        if (selectedProjectFilters !== appContext.state.selectedProjectFilters) {
            setSelectedProjectFilters(appContext.state.selectedProjectFilters);
            fetchData();
        }
    }, [appContext.state.selectedProjectFilters, selectedProjectFilters, navigate]);

    useEffect(() => {
        const existingStorage = window.sessionStorage.getItem(HIGHLIGHTED_ITEMS_KEY);
        if (existingStorage) {
            const existingHighlightedItems = JSON.parse(existingStorage) as string[];
            if (existingHighlightedItems?.length !== highlightedItemIds?.length) {
                const stringToStore = JSON.stringify(highlightedItemIds);
                window.sessionStorage.setItem(HIGHLIGHTED_ITEMS_KEY, stringToStore);
            }
        } else {
            window.sessionStorage.setItem(HIGHLIGHTED_ITEMS_KEY, JSON.stringify(highlightedItemIds));
        }
    }, [highlightedItemIds]);

    useEffect(() => {
        let itemsToShow = items;
        if (!showInactive) {
            itemsToShow = itemsToShow.filter(i => i.status !== MeetingItemStatus.OnHold && i.status !== MeetingItemStatus.Cancelled);
        }
        if (!showCompleted) {
            itemsToShow = itemsToShow.filter(i => i.status !== MeetingItemStatus.Completed);
        }
        if (showOnlyPrioritized) {
            itemsToShow = itemsToShow.filter(i => i.prioritized);
        }
        const todayAtNoon = new Date();
        todayAtNoon.setHours(12, 0, 0, 0);
        itemsToShow = itemsToShow.map(i => ({ ...i, 
            overdue: i.status !== MeetingItemStatus.Completed && i.status !== MeetingItemStatus.Cancelled && i.dueDate ? new Date(i.dueDate) < todayAtNoon : false 
        }));
        setGridItems(itemsToShow);
    }, [showCompleted, showInactive, items, showOnlyPrioritized]);

    async function fetchData() {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.list();
            setItems(convertToRepresentation(response).filter(i => !i.isSnapshot));
        }
    }

    const onArchived = (archived: boolean) => {
        onArchivedAsync(archived);
    }

    const onArchivedAsync = async (archived: boolean) => {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.list({ archived });
            setItems(convertToRepresentation(response).filter(i => !i.isSnapshot));
        }
    };

    const onCompleted = (completed: boolean) => {
        onCompletedAsync(completed);
    }

    const onCompletedAsync = async (completed: boolean) => {
        setShowCompleted(completed);
    };

    const onInactive = (inactive: boolean) => {
        onInactiveAsync(inactive);
    }

    const onInactiveAsync = async (inactive: boolean) => {
        setShowInactive(inactive);
    };

    const onShowOnlyPrioritized = async (showOnlyPrioritized: boolean) => {
        onShowOnlyPrioritizedAsync(showOnlyPrioritized);
    }

    const onShowOnlyPrioritizedAsync = async (showOnlyPrioritized: boolean) => {
        setShowOnlyPrioritized(showOnlyPrioritized);
    };

    async function executeCallbackIfDiffsExist(callback?: () => Promise<void>) {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.listDiffs();
            if (response.length) {
                const highlightsToStore = highlightedItemIds?.length ? [...highlightedItemIds] : [];
                const newHighlightedIds = response.map(i => i.id);
                if (newHighlightedIds) {
                    for (const highlightedItemId of newHighlightedIds) {
                        if (!highlightsToStore.includes(highlightedItemId)) {
                            highlightsToStore.push(highlightedItemId);
                        }
                    }
                    setHghlightedItemIds(highlightsToStore);
                }
                if (callback) {
                    callback();
                }
            }
        }
    }

    useEffect(() => {
        executeCallbackIfDiffsExist();
        // Always fetch data on the initial load
        fetchData();
        const interval = setInterval(() => {
            executeCallbackIfDiffsExist(fetchData);
        }, 10000);
        return () => clearInterval(interval);
    }, [navigate, showAttachmentsForMeeting, showAttachmentsForItem]);

    const onItemUpserted = async (item: MeetingItem) => {
        return await actions.items.save(item.meetingId, item);
    }

    const processRowUpdate = (newRow: GridRowModel<MeetingItemRepresentation>) => {
        if (!hasRepresentationChanged(newRow)) {
            return newRow;
        }
        const statusUpdated = isStatusUpdated(newRow);
        if (statusUpdated) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            addStatusUpdateSystemCommentToItem(newRow, userFullName, t)
        }
        newRow.data = getMeetingItemFromRepresentation(newRow);
        setItems(items.map((row) => (row.id === newRow.id ? newRow : row)));
        onItemUpserted(newRow.data);
        return newRow;
    };

    const openItemEditingPanel = (item: MeetingItemRepresentation) => () => {
        setShowEditingPanelForItem({ item });
    };

    const onItemUpdated = async (itemRepresentation: MeetingItemRepresentation) => {
        const statusUpdated = isStatusUpdated(itemRepresentation);
        if (statusUpdated) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            addStatusUpdateSystemCommentToItem(itemRepresentation, userFullName, t)
        }
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        setItems(items.map((row) => (row.id === itemRepresentation.id ? itemRepresentation : row)));
        onItemUpserted(item);
    }

    const openMeetingAttachments = (meetingId: string) => () => {
        setShowAttachmentsForMeeting(meetingId);
    };

    const openItemAttachments = (id: GridRowId, meetingId: string) => () => {
        setShowAttachmentsForItem({ id, meetingId });
    };

    const onMeetingSelected = async (meetingId: string) => {
        actions.lists.load(meetingId);
    }

    const onNavigate = (meetingId: string) => {
        if (meetingId) {
            onMeetingSelected(meetingId);
            navigate(`/library/${meetingId}/current`);
        }
    };

    const handleClickNavigation = (item: MeetingItemRepresentation) => () => {
        onNavigate(item?.meetingId);
    };

    const onArchiveToggle = (itemRepresentation: MeetingItemRepresentation) => {
        itemRepresentation.archived = itemRepresentation.archived ? false : true;
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        setItems(items.filter((row) => (row.id !== itemRepresentation.id)));
        onItemUpserted(item);
    };

    const toggleArchive = (itemRepresentation: MeetingItemRepresentation) => () => {
        onArchiveToggle(itemRepresentation);
    };

    const extraActions = (id: GridRowId, row: MeetingItemRepresentation) => {
        return ([
            <GridActionsCellItem
                icon={
                    <TooltipIconWithBadge titleKey={'general.open'}
                        icon={<ChevronRightIcon />}
                        badgeContent={hasUserComments(row) ? "i" : undefined} />}
                label="Edit"
                onClick={openItemEditingPanel(row)}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={
                    <TooltipIconWithBadge
                        badgeContent={row.meetingAttachments}
                        titleKey='dashboard.meetingAttachments'
                        icon={<MeetingAttachment color={theme.palette.primary.main} />}
                    />
                }
                label={t('dashboard.meetingAttachments_label')}
                onClick={openMeetingAttachments(row.meetingId)}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={
                    <TooltipIconWithBadge
                        badgeContent={row.attachments}
                        titleKey='dashboard.itemAttachments'
                        icon={< AttachFileIcon />}
                    />
                }
                label={t('dashboard.itemAttachments_label')}
                onClick={openItemAttachments(row.id, row.meetingId)}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                icon={
                    <TooltipIcon titleKey={'meeting.open'}
                        icon={<EventNoteOutlinedIcon fontSize='medium' />} />}
                label={t('meeting.open')}
                sx={{
                    color: 'primary.main',
                }}
                onClick={handleClickNavigation(row)}
            />,
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={row.archived ? 'general.unarchive' : 'general.archive'} icon={row.archived ? <UnarchiveIcon /> : <ArchiveOutlinedIcon />} />}
                label="Archive"
                onClick={toggleArchive(row)}
                sx={{
                    color: 'primary.main',
                }}
            />,
        ]);
    }

    const columns: GridColDef[] = [
        {
            ...riskColumn,
            renderCell: (params) => (<Box
                sx={{
                    backgroundColor: getRiskColor(params.row.risk), 
                    width: 'auto',
                    height: '32px',    
                    margin: '10px',
                }}
            ></Box>)
        },
        {...createdDateColumn, 
            renderCell: (params) => (
                <Badge color="primary" badgeContent=" " variant="dot"
                    sx={{ display: 'inline' }}
                    invisible={!highlightedItemIds.includes(params.row.id)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <span>{createdDateColumn.valueFormatter(params.value)}</span>
                </Badge>
            )
        },
        { ...meetingNameColumn, width: 150 },
        categoryColumn,
        { ...contentColumn, flex: 0.6 },
        {
            ...prioritizedColumn as GridColDef,
            renderCell: (params) => (
                <Box>
                    {params.row.prioritized
                        && (
                            <Tooltip title={t('item.prioritized')}>
                                <span>
                                    {params.row.dueDate && params.row.overdue ?
                                        <PriorityHighIcon sx={{
                                            color: theme.palette.error.main,
                                        }} />
                                        : <PriorityHighIcon />
                                    }
                                </span>
                            </Tooltip>
                        )}
                </Box>
            ),
        },
        {
            ...overdueColumn as GridColDef,
            renderCell: (params) => (
                <Box>
                    {params.row.overdue
                        && (
                            <Tooltip title={t('item.overdue')}>
                                <span>
                                    <AlarmIcon color={theme.palette.error.main} />
                                </span>
                            </Tooltip>
                        )}
                </Box>
            ),
        },
        dueDateColumn,
        {
            ...statusColumn,
            type: 'singleSelect',
            valueFormatter: (value: string) => t(`item_status.${value}`),
            valueOptions: statusTranslatedLabelOptions(t),
            renderCell: (params) => (
                <ItemStatus status={params.row.status} lastModifiedBy={params.row.statusLastUpdatedBy}></ItemStatus>
            ),
        },
        { ...creatorColumn, width: 150 },
        // meetingAttachmentsColumn,
        // itemAttachmentsColumn,
    ];

    async function updateSortModel(newSortModel: GridSortModel) {
        localStorage.setItem(ACTOR_SORT_FIELD, newSortModel[0]?.field);
        localStorage.setItem(ACTOR_SORT_DIRECTION, newSortModel[0]?.sort);
    }

    return (
        <Box>
            <CssBaseline />
            {showAttachmentsForMeeting
                && (
                    <AttachmentsPane
                        allowModifications={false}
                        height='70vh'
                        uploadPrefix={`meetings/${showAttachmentsForMeeting}/`}
                        meetingId={showAttachmentsForMeeting}
                        onClose={() => setShowAttachmentsForMeeting(undefined)}
                        onAttachmentsCountChanged={(count, meetingId, _itemId) => setItems(items.map(item => item.meetingId === meetingId ? { ...item, meetingAttachments: count } : item))}
                    />
                )}
            {showEditingPanelForItem
                && (
                    <ItemEditingPanel 
                    allowAttachmentsEditing={true}
                    meetingItem={showEditingPanelForItem.item} 
                    open={showEditingPanelForItem} 
                    onClose={() => setShowEditingPanelForItem(undefined)} 
                    onUpdate={onItemUpdated}
                    onAttachmentsCountChanged={(count, _meetingId, itemId) => setItems(items.map(item => item.id === itemId ? { ...item, attachments: count } : item))}
                    />
                )}
            {showAttachmentsForItem
                && (
                    <AttachmentsPane
                        allowModifications={true}
                        height='70vh'
                        uploadPrefix={`items/meetings/${showAttachmentsForItem.meetingId}/items/${showAttachmentsForItem.id}/`}
                        meetingId={showAttachmentsForItem.meetingId}
                        itemId={showAttachmentsForItem.id}
                        onClose={() => setShowAttachmentsForItem(undefined)}
                        onAttachmentsCountChanged={(count, _meetingId, itemId) => setItems(items.map(item => item.id === itemId ? { ...item, attachments: count } : item))}
                    />
                )}
            <EditableGrid
                height='90vh'
                processRowUpdate={processRowUpdate}
                rows={gridItems}
                columns={columns}
                sortField={localStorage.getItem(ACTOR_SORT_FIELD)}
                sortDirection={localStorage.getItem(ACTOR_SORT_DIRECTION) as GridSortDirection}
                updateSortModel={updateSortModel}
                toolbar={CustomToolbar}
                extraActions={extraActions}
                isCellEditable={(params) => params.field !== 'dueDate' || !params.row.prioritized || params.row.creator === instance.getActiveAccount()?.username}
                toolbarProps={
                    {
                        toolbar: {
                            showSettings: false,
                            onArchived: onArchived,
                            onCompleted: onCompleted,
                            onInactive: onInactive,
                            onShowOnlyPrioritized: onShowOnlyPrioritized
                        }
                    }
                }
            />

        </Box >
    );
};

export default withApplicationInsights(ActorDashboard, "MyDashboard");
